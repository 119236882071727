import { getAppRole, getAuthUser } from 'utils';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Form, Formik } from 'formik';

import { Button, Menu, Select, Spin } from 'antd';

import { PushpinOutlined } from '@ant-design/icons';

import DropZone from 'components/shared/DropZone';
import ModalMessage from 'components/shared/ModalMessage';
import UserIcon from 'components/shared/UserIcon';
import { ROLES } from 'constants/global';
import { Topic } from 'constants/types';
import {
  useGetCommunitiesQuery,
  useGetCommunityQuery,
} from 'services/communityManagement';
import { useGetAllTopicsCategoriesQuery } from 'services/topicCategoryManagement';
import {
  useDeleteTopicMutation,
  useUpdateTopicMutation,
} from 'services/topicManagement';

import {
  Bullet,
  CommunityName,
  ImagePreviewContainer,
  RemoveImageButton,
  ScrollableContentContainer,
  StyledCategoryLabel,
  StyledCloseOutlined,
  StyledForm,
  StyledFormButtons,
  StyledFormItem,
  StyledHeader,
  StyledLabel,
  StyledModal,
  StyledPinButton,
  StyledPostCategory,
  StyledRole,
  StyledSelectWrapper,
  StyledTextArea,
  StyledUserDetails,
  StyledUserName,
} from '../../../../CreateTopic/components/CreateTopicModal/styles';
import { StyledDropdown } from './styles';

interface DropdownMenuProps {
  topic: Topic;
  refetchPinned: () => void;
  refetchUnpinned: () => void;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  topic,
  refetchPinned,
  refetchUnpinned,
}) => {
  const { t } = useTranslation();
  const [deleteTopic] = useDeleteTopicMutation();
  const [updateTopic] = useUpdateTopicMutation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { data: categories = [] } = useGetAllTopicsCategoriesQuery();
  const { data: communities = [] } = useGetCommunitiesQuery();
  const { data: community } = useGetCommunityQuery();

  const userRole = getAppRole();

  const [UploadedFiles, setUploadedFiles] = useState<
    Array<{ file: File | null; preview: string }>
  >([]);

  useEffect(() => {
    const existingMedia = (topic.media || []).map((media) => ({
      file: null,
      preview: `${process.env.REACT_APP_BACKEND_FORUM_URL}/medias/${
        media.mediaType === 'image' ? 'images' : 'videos'
      }/${media.filename}`,
    }));
    setUploadedFiles(existingMedia);
  }, [topic.media]);

  const initialFormValues = {
    description: topic.description || '',
    category: topic.category || categories[0]?._id || '',
    communities: topic.communities || [],
    pinned: topic.pinned || false,
  };

  const onDeleteTopic = useCallback(async () => {
    setIsDeleteModalOpen(false);
    await deleteTopic(topic._id);
    refetchPinned();
    refetchUnpinned();
  }, [topic._id, deleteTopic, refetchPinned, refetchUnpinned]);

  const onToggleEditModal = useCallback(() => {
    setIsEditModalOpen((prev) => !prev);
  }, []);

  const onSaveEdit = async (values: typeof initialFormValues) => {
    const updatedTopic: Topic = {
      ...topic,
      description: values.description,
      pinned: values.pinned,
      communities: values.communities,
      category: values.category,
    };

    const formData = new FormData();
    formData.append('description', updatedTopic.description);
    formData.append('category', updatedTopic.category);
    formData.append('pinned', String(updatedTopic.pinned));

    let communitiesArray;
    if (userRole === ROLES.PMO && community?.id) {
      communitiesArray = [community.id];
    } else {
      communitiesArray = Array.isArray(updatedTopic.communities)
        ? updatedTopic.communities
        : [updatedTopic.communities];
    }

    communitiesArray.forEach((community) =>
      formData.append('communities', community),
    );

    UploadedFiles.forEach(({ file }) => {
      if (file) {
        formData.append('mediaFiles', file);
      }
    });

    await updateTopic({ id: topic._id, updatedTopic: formData });
    refetchPinned();
    refetchUnpinned();
    setIsEditModalOpen(false);
  };

  const handleDrop = (acceptedFiles: File[]) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const removeImage = (index: number) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleRemoveFileClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    index: number,
  ) => {
    e.preventDefault();
    removeImage(index);
  };

  const menu = (
    <Menu>
      <Menu.Item key="edit" onClick={onToggleEditModal}>
        {t('edit')}
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => setIsDeleteModalOpen(true)}>
        {t('delete')}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {isDeleteModalOpen && (
        <ModalMessage
          messageType="warining"
          title={t('delete_topic_title')}
          subTitle={t('delete_topic_sub_title')}
          btnText={t('ok')}
          handleBtn={onDeleteTopic}
          onCancelClick={() => setIsDeleteModalOpen(false)}
        />
      )}

      {isEditModalOpen && (
        <StyledModal
          title={t('edit_topic')}
          open={isEditModalOpen}
          onCancel={onToggleEditModal}
          footer={null}
        >
          <Spin spinning={false} size="large" tip={t('loading')}>
            <Formik initialValues={initialFormValues} onSubmit={onSaveEdit}>
              {({ setFieldValue, values }) => (
                <Form>
                  <StyledForm>
                    <StyledHeader>
                      <StyledUserDetails>
                        <UserIcon
                          firstName={getAuthUser().firstName}
                          lastName={getAuthUser().lastName}
                          userRole={getAppRole()}
                        />
                        <div>
                          <StyledUserName>
                            <StyledRole>
                              {userRole === ROLES.PMO ? 'PMO' : 'OYO'}
                            </StyledRole>
                            <Bullet>•</Bullet>
                            <CommunityName>
                              {userRole === ROLES.PMO
                                ? communities.find(
                                    (com) => com.id === values.communities[0],
                                  )?.communityName || ''
                                : 'OYO Communities'}
                            </CommunityName>
                          </StyledUserName>

                          <StyledPostCategory>
                            <StyledCategoryLabel>
                              {t('post_category')}
                            </StyledCategoryLabel>
                            <StyledSelectWrapper>
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                onChange={(value) =>
                                  setFieldValue('category', value)
                                }
                                value={values.category}
                              >
                                {categories.map((category) => (
                                  <Select.Option
                                    key={category._id}
                                    value={category._id}
                                  >
                                    {t(category.title)}
                                  </Select.Option>
                                ))}
                              </Select>
                            </StyledSelectWrapper>
                          </StyledPostCategory>
                        </div>
                      </StyledUserDetails>
                      <StyledPinButton
                        pinned={values.pinned}
                        icon={<PushpinOutlined />}
                        onClick={() => setFieldValue('pinned', !values.pinned)}
                      >
                        {t(values.pinned ? 'pinned_post' : 'pin_post')}
                      </StyledPinButton>
                    </StyledHeader>

                    {userRole !== ROLES.PMO && (
                      <StyledFormItem>
                        <StyledLabel>{t('share_post_in')}</StyledLabel>
                        <Select
                          style={{ width: '386px', margin: '0 12px' }}
                          getPopupContainer={(trigger) => trigger.parentNode}
                          onChange={(value) =>
                            setFieldValue('communities', value)
                          }
                          value={values.communities}
                        >
                          {communities.map((community) => (
                            <Select.Option
                              key={community.id}
                              value={community.id}
                            >
                              {community.communityName}
                            </Select.Option>
                          ))}
                        </Select>
                      </StyledFormItem>
                    )}

                    <ScrollableContentContainer>
                      <StyledFormItem>
                        <Field
                          name="description"
                          as={StyledTextArea}
                          placeholder={t('what_about')}
                        />
                      </StyledFormItem>

                      {UploadedFiles.length === 0 && (
                        <StyledFormItem>
                          <DropZone
                            onDrop={(acceptedFiles: File[]) => {
                              handleDrop(acceptedFiles);
                              setFieldValue('files', [
                                ...UploadedFiles.map((f) => f.file),
                                ...acceptedFiles,
                              ]);
                            }}
                          />
                        </StyledFormItem>
                      )}

                      {UploadedFiles.map(({ preview }, index) => (
                        <ImagePreviewContainer key={index}>
                          {preview.includes('images') ? (
                            <img src={preview} alt={`Preview ${index + 1}`} />
                          ) : (
                            <video
                              controls
                              src={preview}
                              style={{ maxWidth: '100%', borderRadius: '10px' }}
                            />
                          )}
                          <RemoveImageButton
                            onClick={(e) => handleRemoveFileClick(e, index)}
                          >
                            <StyledCloseOutlined />
                          </RemoveImageButton>
                        </ImagePreviewContainer>
                      ))}
                    </ScrollableContentContainer>

                    <StyledFormButtons>
                      <Button type="primary" htmlType="submit">
                        {t('post')}
                      </Button>
                    </StyledFormButtons>
                  </StyledForm>
                </Form>
              )}
            </Formik>
          </Spin>
        </StyledModal>
      )}

      <StyledDropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomRight"
      >
        <span>...</span>
      </StyledDropdown>
    </>
  );
};

export default DropdownMenu;